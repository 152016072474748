<template>
  <div class="user">
    <div v-if="!isApply">
      <div class="tit">{{ $t('reg_applySuccess_1') }}</div>
      <div class="user-box">
        <div class="limit-tit">
          {{ $t('请立即支付') }}
          {{ registerMoney }}
          {{ $t('开户费用 ,便于为您开户 。') }}
        </div>
      </div>
      <div class="textCenter">
        <el-button :loading="nextLoading" type="primary" @click="openApply">
          {{ $t('reg_applySuccess_3') }}
        </el-button>
      </div>
    </div>

    <div class="applyOk" v-else>
      <i class="el-icon-success"></i>
      <div class="emsTip">
        {{ $t('reg_applySuccess_4') }}
      </div>
    </div>
    <usdPayment
      :payData="payData"
      :type="2"
      :isshow.sync="applyVisible"
      @updateData="next"
    ></usdPayment>
  </div>
</template>

<script>
import usdPayment from '@/components/pay/usdPayment.vue'
import { getUrlArgs } from '@/utils/tool.js'
export default {
  name: 'Email',
  components: {
    usdPayment,
  },
  data() {
    return {
      ruleForm: {
        step: this.$route.meta.sort,
        location_url: this.$route.meta.name,
        email: localStorage.getItem('sm_email'),
      },
      payData: {
        type: 1,
      },
      payEmail: getUrlArgs('email'),
      PayMessage: {},
      applyVisible: false,
      isApply: false,
      nextLoading: false,
      registerMoney: '',
    }
  },
  created() {
    this.getRegisterMoney()
    this.isApply = false
    if (localStorage.getItem('sm_userinfo')) {
      let userInfo = JSON.parse(localStorage.getItem('sm_userinfo'))
      if (this.payEmail) {
        userInfo.step = 9
      }
      if (userInfo.step == 10) {
        this.isApply = true
      }
    }
  },
  methods: {
    async getRegisterMoney() {
      let res = await this.$axios.post('/api/Common/getRegisterMoney')
      if (res.code === 1) {
        this.registerMoney = res.data
      }
    },
    async openApply() {
      let url = ''
      let data = {}
      if (this.payEmail) {
        url = '/api/Config/emailPay'
        data.email = this.payEmail
      } else {
        url = '/api/Config/registerPay'
      }
      let res = await this.$axios.post(url, data)
      if (res.code === 1) {
        this.payData = {
          ...this.payData,
          ...res.data,
        }
        this.applyVisible = true
      }
    },
    async next() {
      if (this.payEmail) {
        let data = {
          ...this.ruleForm,
          order_id: this.payData.order_id,
        }
        this.nextLoading = true
        let res = await this.$axios.post('api/Config/updStep', data)
        this.nextLoading = false
        if (res.code === 1) {
          this.isApply = true
          // localStorage.setItem('sm_userinfo', JSON.stringify(res.data.userinfo))
        }
      } else {
        this.nextLoading = true
        let res = await this.$axios.post('/api/User/register', this.ruleForm)
        this.nextLoading = false
        if (res.code === 1) {
          this.isApply = true
          localStorage.setItem('sm_userinfo', JSON.stringify(res.data.userinfo))
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  width: 600px;
  margin: 100px auto 0;
  .tit {
    font-size: 30px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 3px;
    margin-bottom: 40px;
    text-align: center;
  }
}
.limit-tit {
  font-size: 24px;
  color: #333333;
  margin-bottom: 10px;
}
.textCenter {
  margin-top: 50px;
  .el-button {
    width: 100%;
    margin-left: 0;
  }
}
.applyRwm {
  width: 120px;
  height: 120px;
}
.tip {
  font-size: 12px;
  color: #999999;
  padding-left: 20px;
}
.applyOk {
  text-align: center;
  margin-top: 120px;
  i {
    font-size: 60px;
    color: #409eff;
  }
  .emsTip {
    font-size: 14px;
    margin-top: 30px;
  }
}
</style>
